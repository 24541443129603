/**
 * Active Select2 Plugin if there is select2 class
 */
var select2PluginClass = 'select2';

if (select2PluginClass.length) {
	$(".select2").select2();
}

/**
 * Bootstrap tooltip
 */
$('[data-toggle="tooltip"]').tooltip({
    trigger : 'hover'
});

/**
 * Create URL for Bootstrap Tabs
 */
var url = document.location.toString();

if (url.match('#')) {
    $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
} 

$('.nav-tabs a').on('shown.bs.tab', function (e) {
    window.location.hash = e.target.hash;
})

/**
 * Sidebar toggle using Ajax
 */
$('.sidebar-toggle').on('click', function() {
	$.get('/api/sidebar-toggle');
});

/**
 * Send order data using Ajax
 */
$('.send-data').on('click', function() {
	$.get($(this).attr('data-href')).done(function() {
		$('.callout').removeClass('hidden');
  	});
});

/**
 * Order shipping details using modal and ajax
 */
$('.more-details').on('click', function() {
	$('.modal-body').html('<p class="text-center"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i><span class="sr-only">Loading...</span></p>');

	$.ajax({
		type: 'get',
		url: $(this).attr('data-href'),
		success: function(data) {
			if (data.success) {
				$('.modal-body').html(data.content);
			} else {
				alert(data);
			}
		},
		error: function(xhr, textStatus, thrownError) {
			alert(xhr.status);
			alert(thrownError);
		}
	});
});